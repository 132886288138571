:root {
  --font-primary: "Inter", -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
}

body {
  margin: 0;
  font-family: var(--font-primary) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 17px;
  line-height: 1.47059;
  letter-spacing: -0.022em;
  font-weight: 400;
}

* {
  font-family: var(--font-primary) !important;
  font-size: 17px;
  line-height: 1.47059;
  letter-spacing: -0.022em;
}

h1, .h1 {
  font-size: 48px;
  line-height: 1.08349;
  letter-spacing: -0.003em;
  font-weight: 600;
}

h2, .h2 {
  font-size: 40px;
  line-height: 1.1;
  letter-spacing: 0em;
  font-weight: 600;
}

h3, .h3 {
  font-size: 32px;
  line-height: 1.125;
  letter-spacing: 0.004em;
  font-weight: 600;
}

h4, .h4 {
  font-size: 24px;
  line-height: 1.16667;
  letter-spacing: 0.009em;
  font-weight: 600;
}

h5, .h5 {
  font-size: 21px;
  line-height: 1.19048;
  letter-spacing: 0.011em;
  font-weight: 600;
}

p, .body1, button, .button, input, textarea, select {
  font-size: 17px;
  line-height: 1.47059;
  letter-spacing: -0.022em;
  font-weight: 400;
}

/* Mantenemos 17px incluso en móvil */
@media only screen and (max-width: 734px) {
  body, p, .body1, button, .button, input, textarea, select {
    font-size: 17px;
    line-height: 1.47059;
  }

  h1, .h1 {
    font-size: 40px;
    line-height: 1.1;
  }

  h2, .h2 {
    font-size: 32px;
    line-height: 1.125;
  }

  h3, .h3 {
    font-size: 28px;
    line-height: 1.14286;
  }

  h4, .h4 {
    font-size: 24px;
    line-height: 1.16667;
  }

  h5, .h5 {
    font-size: 21px;
    line-height: 1.19048;
  }
}
