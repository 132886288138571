/* fonts.css */

/* IMPORTAMOS INTER DESDE GOOGLE FONTS */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Usamos Inter como fuente principal con fallback */
* {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif !important;
}

/* Aplica la fuente a todos estos elementos para ser consistentes */
body, 
button, 
input, 
select, 
textarea,
h1, h2, h3, h4, h5, h6,
p, span, div {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif !important;
}
